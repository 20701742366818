import IconButton from '@seaweb/coral/components/IconButton';
import React from 'react';
import CrossIcon from '@seaweb/coral/icons/Cross';

export function CrossIconButton({ ...props }) {
  return (
    <IconButton {...props}>
      <CrossIcon color={'#F5222D'} />
    </IconButton>
  );
}

import IconButton from '@seaweb/coral/components/IconButton';
import React from 'react';
import CheckIcon from '@seaweb/coral/icons/Check';

export function CheckIconButton(props) {
  return (
    <IconButton {...props}>
      <CheckIcon color={'#16C42A'} />
    </IconButton>
  );
}

// this file exports a svgs map to allow user get svg by name
// https://github.com/facebook/create-react-app/pull/3718
import { ReactComponent as ADD_EMPLOYEE } from './svg/icon_add_employee.svg';
import { ReactComponent as ADMIN_LIST } from './svg/icon_admin_list.svg';
import { ReactComponent as APP_CENTER } from './svg/icon_app_center.svg';
import { ReactComponent as CHECK_IN } from './svg/icon_check_in.svg';
import { ReactComponent as CHECKIN } from './svg/icon_checkin.svg';
import { ReactComponent as CLAIM } from './svg/icon_claim.svg';
import { ReactComponent as COMPANY } from './svg/icon_company.svg';
import { ReactComponent as CONFIGURE } from './svg/icon_configure.svg';
import { ReactComponent as DEPARTMENT } from './svg/icon_department.svg';
import { ReactComponent as FILETYPE_EXCEL } from './svg/icon_filetype_excel.svg';
import { ReactComponent as GOOGLE } from './svg/icon_google.svg';
import { ReactComponent as IMPORT } from './svg/icon_import.svg';
import { ReactComponent as INVITE } from './svg/icon_invite.svg';
import { ReactComponent as LARGE_FILE } from './svg/icon_large_file.svg';
import { ReactComponent as LEAVE_ATTENDANCE } from './svg/icon_leave_attendance.svg';
import { ReactComponent as ROLE } from './svg/icon_role.svg';
import { ReactComponent as TOAST_ERROR } from './svg/icon_toast_error.svg';
import { ReactComponent as UPLOAD } from './svg/icon_upload.svg';
import { ReactComponent as INFO } from './svg/icon_toast_info.svg';
import { ReactComponent as SUCCESS } from './svg/icon_toast_success.svg';
import { ReactComponent as WARNING } from './svg/icon_toast_warning.svg';
import { ReactComponent as ICON_DOWNLOAD } from './svg/icon_download.svg';
import { ReactComponent as ICON_PLUS } from './svg/icon_plus.svg';

const svgs = {
  add_employee: ADD_EMPLOYEE,
  admin_list: ADMIN_LIST,
  app_center: APP_CENTER,
  check_in: CHECK_IN,
  checkin: CHECKIN,
  claim: CLAIM,
  company: COMPANY,
  configure: CONFIGURE,
  department: DEPARTMENT,
  filetype_excel: FILETYPE_EXCEL,
  google: GOOGLE,
  import: IMPORT,
  invite: INVITE,
  large_file: LARGE_FILE,
  leave_attendance: LEAVE_ATTENDANCE,
  role: ROLE,
  toast_error: TOAST_ERROR,
  upload: UPLOAD,
  info: INFO,
  success: SUCCESS,
  warning: WARNING,
  icon_download: ICON_DOWNLOAD,
  plus: ICON_PLUS,
};

export default svgs;
